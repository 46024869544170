import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Lindenthal: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Lindenthal,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Lindenthal"
          d="M444,644.1c-1,4.1,3,17.8,4,21.9l12,4l-2,12l-38,32l-22,4l-42,32l-14-8l-7.4,10.4l-4.3-3l-1.8,1.3 c-1-0.6-17.9-59.8-18.5-60.7l14-16l-2-8c26.3-11.8,20.3-12.8,50-12l6-4l4,6l28-12l-2-22l12-10l24,22V644.1z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 351.9067 692.2032)"
          textRendering="geometricPrecision"
        >
          Lindenthal
        </text>
      </g>
    </>
  );
});
